import { getPhoneCountryCode } from "./get-phone-country-code";
import { phoneMasks } from "./phone-masks";

export const getPhoneMaskList = (phone: string) => {
  const code = getPhoneCountryCode(phone) ?? "";
  const masks = phoneMasks[code] ?? [];

  if (!masks.length) {
    return ["+###################"];
  }

  return masks;
};

export const getPhoneMask = (phone: string) => {
  return JSON.stringify(getPhoneMaskList(phone));
};
