<script lang="ts" setup>
import { type InputType } from "./protocols";
import PhoneInput from "./PhoneInput.vue";
import RegularInput from "./RegularInput.vue";
import CurrencyInput from "./CurrencyInput.vue";

interface Props {
  inputType?: InputType;
  modelValue: any;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:modelValue", data: any): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const inputComponent = computed(() => {
  switch (props.inputType) {
    case "phone":
      return PhoneInput;
    case "money":
      return CurrencyInput;
    default:
      return RegularInput;
  }
});
</script>

<template>
  <component :is="inputComponent" v-model="value" />
</template>
