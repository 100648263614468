import { phoneMasks } from "./phone-masks";

export const getPhoneCountryCode = (phone: string) => {
  const parsed = phone?.replace(/\D/g, "") ?? "";
  const possibleCountryCodes = [
    parsed.slice(0, 1),
    parsed.slice(0, 2),
    parsed.slice(0, 3),
  ];

  return possibleCountryCodes.find((code) => phoneMasks[code]);
};
