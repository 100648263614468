<script lang="ts" setup>
import { usePhoneMask } from "@/composables/forms/usePhoneMask";

interface Props {
  modelValue: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:modelValue", data: any): void;
}>();

const unmaskedContent = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const mask = usePhoneMask(unmaskedContent);
const maskedContent = ref("");
const debouncedMaskedContent = useDebounce(maskedContent, 50);

watch(debouncedMaskedContent, (masked) => {
  const withoutMask = masked.replace(/\D/g, "");

  if (unmaskedContent.value !== withoutMask) {
    unmaskedContent.value = withoutMask;
  }
});

watch(
  unmaskedContent,
  () => {
    if (maskedContent.value.replace(/\D/g, "") !== unmaskedContent.value) {
      maskedContent.value = unmaskedContent.value;
    }
  },
  { immediate: true },
);
</script>

<template>
  <input v-model="maskedContent" v-maska :data-maska="mask" />
</template>
